













import { Vue, Component, Watch } from "vue-property-decorator";
import { $overlay } from "@/services/OverlayService";
import { $whitelabel } from "./services/WhitelabelService";
import { ApplicationWhitelabel } from "./services/types/Application";

@Component
export default class App extends Vue {
  get overlay() {
    return $overlay.show;
  }

  get whitelabel() {
    return $whitelabel.getState().whitelabel;
  }

  get appStyles() {
    return {
      backgroundColor: "#" + this.whitelabel?.background_color,
    };
  }

  async mounted() {
    $overlay.show = true;
  }

  @Watch("whitelabel")
  onWhitelabelLoad(value: ApplicationWhitelabel | null) {
    if (value) {
      this.$el.classList.remove("no-whitelabel");
    } else {
      this.$el.classList.add("no-whitelabel");
    }
  }
}
