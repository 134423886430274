import { config } from "@/config";
import { AgileWSClient, AxiosHttpService } from "agilews-lib";
import Service from "./core/Service";

type ApiState = {
  client: AgileWSClient;
};

class ApiService extends Service<ApiState> {
  protected state!: ApiState;

  constructor() {
    super();

    const axios = new AxiosHttpService();

    this.state = {
      client: new AgileWSClient(axios),
    };

    this.state.client.setTransactionsBaseUrl(config.api.transaction.url);
    this.state.client.setValidationsBaseUrl(config.api.twofa.url);
  }

  get client() {
    return this.state.client;
  }
}

export const $api = Service.singleton(ApiService);
