import { Transaction } from "agilews-lib";
import { $api } from "./ApiService";
import Service from "./core/Service";

type TransactionState = {
  transaction: Transaction | null;
};

class TransactionService extends Service<TransactionState> {
  protected state: TransactionState = {
    transaction: null,
  };

  get transaction() {
    return this.state.transaction;
  }

  public async load(transaction_id: string) {
    try {
      const resp = await $api.client.transactions.fetch(transaction_id);
      if (resp.error) {
        console.log(resp.error.message);
      } else {
        this.setState({ transaction: resp.data });
      }
      return this.getState().transaction;
    } catch (err) {
      return this.getState().transaction;
    }
  }
}

export const $transaction = Service.singleton(TransactionService);
