export const config = {
  api: {
    transaction: {
      url: "https://test.validation.agilesigner.com/api-transaction/api/public",
    },
    twofa: {
      url: "https://test.validation.agilesigner.com/api-2fa/api/2fa",
    },
  },
};
