import Service from "./core/Service";

type OverlayState = {
  show: boolean
};

class OverlayService extends Service<OverlayState> {
  protected state = {
    show: true  
  };

  get show() {
    return this.state.show;
  }

  set show(val: boolean) {
    this.setState({ show: val });
  }
}

export const $overlay = Service.singleton(OverlayService);
