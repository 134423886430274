import { Vue } from "vue-property-decorator";

export default abstract class Service<S> {
  protected abstract state: S;

  public getState(): S {
    return this.state;
  }

  protected setState(newState: Partial<S>) {
    return Object.assign(this.state, newState);
  }

  static bootstrap<T extends Service<any>>(obj: T) {
    Vue.observable(obj.state);
    return obj;
  }

  static singleton<T extends Service<any>>(
    klass: { new (...args: any[]): T },
    ...args: any[]
  ) {
    return Service.bootstrap(new klass(args));
  }
}
