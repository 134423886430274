import { Whitelabel } from "agilews-lib";
import { $api } from "./ApiService";
import Service from "./core/Service";

type WhitelabelState = {
  whitelabel: Whitelabel | null;
};

class WhitelabelService extends Service<WhitelabelState> {
  protected state = {
    whitelabel: null,
  };

  get loaded() {
    return this.state.whitelabel != null;
  }

  public async load(application_id: string) {
    try {
      const resp = await $api.client.applications.fetchWhitelabel(
        application_id
      );
      if (resp.error) {
        console.log(resp.error.error);
      } else {
        this.setState({ whitelabel: resp.data });
      }
      return this.getState().whitelabel;
    } catch (err) {
      return this.getState().whitelabel;
    }
  }
}

export const $whitelabel = Service.singleton(WhitelabelService);
