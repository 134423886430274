import { $transaction } from "@/services/TransactionService";
import { $whitelabel } from "@/services/WhitelabelService";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/success",
    name: "Success",
    component: () => import("@/views/Success.vue"),
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("@/views/Error.vue"),
  },
  {
    path: "/:id",
    name: "Challenge",
    component: () => import("@/views/Challenge.vue"),
    beforeEnter: async (to, from, next) => {
      if (to.params.id && !$transaction.transaction) {
        // LOADS TRANSACTION USING ID IN URL PARAMS
        const tx = await $transaction.load(to.params.id);
        if (tx != null) {
          // LOADS WHITELABEL IF ENABLED
          if (tx.application.whitelabel_enable)
            await $whitelabel.load(tx.application.id);
          if (tx.success) {
            next({ path: "/success" });
          } else {
            next();
          }
        } else {
          next({ path: "/error", query: { status: "tx_not_found" } });
        }
      } else if ($transaction.transaction) {
        const tx = $transaction.transaction;
        // LOADS WHITELABEL IF ENABLED
          if (tx.application.whitelabel_enable && !$whitelabel.loaded)
            await $whitelabel.load(tx.application.id);
          if (tx.success) {
            next({ path: "/success" });
          } else {
            next();
          }
      } else {
        next({ path: "/error", query: { status: "tx_not_provided" } });
      }
    },
  },
  {
    path: "*",
    redirect: "/error",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
